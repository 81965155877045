import { UserRoles } from "enums/roles";
import { createSelector } from "reselect";

export interface UserProfile {
  profilePreferences: Preferences;
  communityStatus: string;
  profileStatus: boolean;
  relogin: boolean;
  loginStatue: object;
  socketId: string;
  profile: Profile;
  token: string;
  sigup: boolean;
  verified: boolean;
  verifyEmail: string;
  anotherSignOut: boolean;
  isLoadingLogin: boolean;
}

export interface Profile {
  agentID: number;
  communityStatus: string;
  communityConnected: boolean;
  agentKey: string;
  maxActive: number;
  name: string;
  imageURL: string;
  permissions: string[];
  groups: { groupID: string; groupName: string }[];
  role: UserRoles;
  company: string;
  visibility: 0 | 1 | 2;
  preferences: Preferences;
}

export interface Preferences {
  // this interface it totally customizable
  // since it stored as it is in backend
  // object structure
  /* 
    Tab_name : {
      item_related_to_tab : any 
    }
  */
  dashboard: {
    slaPreferences: {
      service: number;
      response: number;
      waiting: number;
    };
  };
  notifications: {
    inbox: {
      active: {
        sound: boolean;
        desktop: boolean;
      };
      inactive: {
        sound: boolean;
        desktop: boolean;
      };
      escalated: {
        sound: boolean;
        desktop: boolean;
      };
      escalatedUnassign: {
        sound: boolean;
        desktop: boolean;
      };
      transferAgent: {
        sound: boolean;
        desktop: boolean;
      };
      transferSuperAgent: {
        sound: boolean;
        desktop: boolean;
      };
    };
    community: {
      channel: boolean;
      direct: boolean;
      group: boolean;
    };
  };
}
interface RootState {
  auth: UserProfile;
}

// This used to memorize the selector value
export const authSelector = createSelector(
  (state: RootState) => state.auth,
  (auth: UserProfile) => {
    return {
      loginStatue: auth.loginStatue,
      socketId: auth.socketId,
      profile: auth.profile,
      profilePreferences: auth.profilePreferences,
      token: auth.token,
      sigup: auth.sigup,
      verified: auth.verified,
      verifyEmail: auth.verifyEmail,
      anotherSignOut: auth.anotherSignOut,
      relogin: auth.relogin,
      profileStatus: auth.profileStatus,
      communityStatus: auth.communityStatus,
      isLoadingLogin: auth.isLoadingLogin,
    };
  }
);
