import { Profile } from "./selector";
import {
  ADD_TOKEN,
  DISABLE_CURRENT_TAB_SESSION,
  GET_PROFILE,
  LOADING_LOGIN,
  LOGIN_STATUS,
  OTHER_LOGOUT,
  RESET_REGIST,
  UPDATE_PROFILE_PREFERENCES,
  UPDATE_PROFILE_STATUE,
  UPDATE_PROFILE_STATUE_COMMUNITY,
  USER_SIGNUP,
  USER_VERIFY,
} from "./types";

interface IntialState {
  loginStatue: {};
  socketId: string;
  profile: Profile | {};
  token: string;
  sigup: boolean;
  verified: boolean;
  verifyEmail: string;
  anotherSignOut: boolean;
  relogin: boolean;
  profileStatus: boolean;
  communityStatus: string;
  isLoadingLogin: boolean;
}

const initialState: IntialState = {
  loginStatue: {},
  socketId: "",
  profile: {},
  token: "",
  sigup: false,
  verified: false,
  verifyEmail: "",
  anotherSignOut: false,
  relogin: false,
  profileStatus: true,
  communityStatus: "",
  isLoadingLogin: false,
};

export default function authReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_STATUS:
      return {
        ...state,
        loginStatue: payload.loginStatus,
        socketId: payload.socketId,
      };
    case GET_PROFILE:
      return {
        ...state,
        profile: payload.profile,
        profilePreferences: payload.profile.preferences,
        profileStatus: payload.profile.visibility !== 2 ? false : true,
        communityStatus: payload.profile.communityStatus,
      };
    case UPDATE_PROFILE_STATUE_COMMUNITY:
      return {
        ...state,
        communityStatus: payload,
      };
    case ADD_TOKEN:
      return {
        ...state,
        token: payload.token,
      };
    case USER_SIGNUP:
      return {
        ...state,
        sigup: true,
        verifyEmail: payload.adminEmail,
      };

    case USER_VERIFY:
      return {
        ...state,
        verified: true,
      };
    case RESET_REGIST:
      return {
        ...state,
        sigup: false,
        verifyEmail: "",
      };
    case OTHER_LOGOUT:
      return {
        ...state,
        anotherSignOut: true,
      };
    case DISABLE_CURRENT_TAB_SESSION:
      return {
        ...state,
        relogin: true,
      };
    case UPDATE_PROFILE_STATUE:
      return {
        ...state,
        profileStatus: payload,
      };
    case UPDATE_PROFILE_PREFERENCES:
      return {
        ...state,
        profilePreferences: payload,
      };
    case LOADING_LOGIN:
      return {
        ...state,
        isLoadingLogin: payload,
      };
    default:
      return state;
  }
}
